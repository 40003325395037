<template>
  <el-card class="card pass-info" :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: 0 }">
    <el-row class="pass-info__header">
      <el-col class="pass-info__header__content">
        <div class="pass-info__header__content__title">
          <span class="pass-info__header__content__title__main">Change Password</span>
          <span class="pass-info__header__content__title__sub">Change your account password</span>
        </div>
        <div class="pass-info__header__content__toolbar">
          <gf-button type="success" @click="handleSave">Save Changes</gf-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="pass-info__body">
      <el-col class="pass-info__body__content">
        <el-form label-width="250px" label-position="left" class="pers-info__body__content__form" :model="password" ref="password" :rules="passwordRules">
          <el-form-item class="el-form-item--custom" label="Current Password" prop="old_pass">
            <el-input class="el-input--dark" show-password placeholder="Current Password" v-model="password.old_pass"></el-input>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="New Password" prop="new_pass">
            <el-input class="el-input--dark" show-password placeholder="New Password" v-model="password.new_pass"></el-input>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="Verify Password" prop="verify_pass">
            <el-input class="el-input--dark" show-password placeholder="Verify Password" v-model="password.verify_pass"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
.card {
  flex-direction: column;
  height: 100%;
}

.pass-info {
  &__header {
    min-height: 70px;
    border-bottom: 1px solid #EBEDF3;

    &__content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9.75px 29.25px;

      &__title {
        display: flex;
        flex-direction: column;
        margin: 6.5px 6.5px 6.5px 0;

        &__main {
          color: #181C32;
          font-weight: 500;
          font-size: 16.575px;
        }

        &__sub {
          color: #b5b5c3;
          font-weight: 500;
          font-size: 12.025px;
          margin-top: 3.25px;
        }
      }
    }
  }

  &__body {
    &__content {
      height: 100%;
      padding: 29.25px;
    }
  }
}
</style>

<script>
import UserProfileService from '@/services/v1/UserProfile'

export default {
  props: {
    active: Number
  },
  data () {
    const passwordLength = (rule, value, cb) => {
      if (value.length < 5 || value.length > 15) return cb(new Error('Password length should be between 5-15 characters'))
      cb()
    }
    const verifyPassword = (rule, value, cb) => {
      if (value !== this.password.new_pass) return cb(new Error('New Password and Verify Password did not match'))
      cb()
    }
    const checkPassword = (rule, value, cb) => {
      const upService = new UserProfileService()
      upService.checkPassword({ password: value })
        .then(() => {
          cb()
        })
        .catch(error => {
          if (error.response.status === 401) return cb(new Error('Wrong password provided'))
          return cb(new Error('Something went wrong!'))
        })
    }
    return {
      password: {
        old_pass: null,
        new_pass: null,
        verify_pass: null
      },

      // Rules
      passwordRules: {
        old_pass: [
          { required: true, message: 'Password can\'t be blank', trigger: 'blur' },
          { validator: passwordLength, trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ],
        new_pass: [
          { required: true, message: 'New Password can\'t be blank', trigger: 'blur' },
          { validator: passwordLength, trigger: 'blur' }
        ],
        verify_pass: [
          { required: true, message: 'Verify Password can\'t be blank', trigger: 'blur' },
          { validator: passwordLength, trigger: 'blur' },
          { validator: verifyPassword, trigger: 'blur' }
        ]
      },

      // services
      upService: null
    }
  },
  methods: {
    handleSave () {
      this.$refs.password.validate()
        .then(async () => {
          try {
            const upService = new UserProfileService()
            await upService.updatePassword({ password: this.password.new_pass })
            this.$message.success('Changes successfully save')
            this.$refs.password.resetFields()
          } catch (error) {
            this.$Error(error)
          }
        })
        .catch(() => {})
    }
  },
  mounted () {
    this.$emit('update:active', 3)
  }
}
</script>
